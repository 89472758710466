<template>
    <KCourse loader-class="MBcont" course-id="3" title="Роспись и парик" :items="items">
        <div class="MBtextcont">
            <h3>4.0 Подготовка к росписи</h3>
        </div>

        <VideoView video-id="abcbd8f6a71d43429cefe292b036b80e"/>

        <div class="MBtextcont">
            <h3>4.1 Наносим основной тон</h3>
        </div>

        <VideoView video-id="3dac2c47800f42b995175e96a839f063"/>
        
        <div class="MBtextcont">
            <h3>4.2 Расписываем глаза</h3>
        </div>

        <VideoView video-id="97288a18e8ec4e899b1d6745f64edac9"/>
        
        <div class="MBtextcont">
            <h3>4.3 Расписываем детали лица</h3>
        </div>

        <VideoView video-id="57e067fc3eca47dab54ae6f9c8676ce1"/>


        <div class="MBtextcont">
            <h3>4.4 Веснушки, родинки, покрываем лаком</h3>
        </div>
        
        <VideoView video-id="48b5fc8fc0e3418ab2fac2715b3bfede"/>

        <div class="MBtextcont">
            <h3>4.5 Расписываем тело</h3>
        </div>

        <VideoView video-id="289a5fd67ddc4977acf03eb138746960"/>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Образ", url:"/mycourses/draw/1"},
                { title: "Подготовка", url:"/mycourses/draw/2"},
                { title: "Грунтуем и красим", url:"/mycourses/draw/3"},
                { title: "Расписываем", url:"/mycourses/draw/4"},
                { title: "Делаем парик", url:"/mycourses/draw/5"},
            ],
        }
        }
    }
</script>